import React, {useCallback, useState} from 'react';
import styled from 'styled-components';
import {DataTable, type DataTablePassThroughOptions, DataTableRowEditCompleteEvent,} from 'primereact/datatable';
import {Complaint, Letter, LetterType} from '@/stub';
import {Column, ColumnBodyOptions, ColumnEditorOptions} from "primereact/column";
import {Button} from "primereact/button";
import {formatToUKDate} from "@/Util/formatToUKDate";
import {useToastMessagesStore} from "@/Stores/ToastMessagesStore";
import {useQueryClient} from "@tanstack/react-query";
import {truncateString} from "@/Util/truncateString";
import PreviewFileModal from "@/components/Modals/PreviewFileModal";
import {snakeToNormalCase} from "@/helpers/general";
import {diffHours} from "@formkit/tempo";
import {clsx} from "clsx";
import BaseCalendar from "@/components/Core/Form/BaseCalendar";
import {QueryKeys} from "@/Service/Api/QueryKeys/QueryKeys";
import {CustomErrorMessage} from "@/Messages/Toast/General/CustomErrorMessage";
import {useGetComplaintLetters} from "@/Service/Api/ApiHooks/Letter/useGetComplaintLetters";
import DownloadButton from "@/components/partials/DownloadButton";
import {useLetterSentDate} from "@/Service/Api/ApiHooks/Letter/useLetterSentDate";
import {
    ComplaintAllDocumentSentDateSavedMessage,
    ComplaintDocumentSentDateSavedMessage
} from "@/Messages/Toast/ComplaintDocuments/ComplaintDocumentSentDateSavedMessage";
import {formatToApiDate} from "@/Util/formatToApiDate";
import {ConfirmDialog} from "primereact/confirmdialog";
import {useUpdateComplaintAllActions} from "@/Service/Api/ApiHooks/ComplaintActions/useUpdateComplaintAllActions";

type TableColumnDefinition = {
    label?: string
    field?: string
    body?: React.ReactNode | ((data: Letter, options: ColumnBodyOptions) => React.ReactNode)
    editor?: (options: ColumnEditorOptions) => React.ReactNode
    rowEditor?: (data: Letter) => boolean
};

const dataTablePtOptions: DataTablePassThroughOptions = {
    root: {
        className: 'datatable-base'
    }
};

const StyledWrap = styled.main`
    .datatable-base {
        font-size: 0.8rem;
        font-weight: 500;
    }

    .signalizing-field {
        padding: 0.15rem 0.5rem;
        border-radius: 6px;
        text-wrap: nowrap;

        &.future {
            background-color: var(--teal-200)
        }

        &.success {
            background-color: var(--green-200);
        }

        &.warning {
            background-color: var(--yellow-100);
        }

        &.danger {
            background-color: var(--red-200);
        }

        &.text-accent {
            font-weight: 700;
        }
    }
`;

export type ComplaintLettersDatatableProps = {
    complaint: Complaint
};

const today = new Date();

const stateBodyRenderGenerator = (letter: Letter) => {
    if (letter.sent_date) {
        const isOverdueSent = letter.due_date < letter.sent_date;
        return (
            <span
                className={clsx('signalizing-field', {'success': !isOverdueSent, 'danger text-accent': isOverdueSent})}>
                Sent: {formatToUKDate(letter.sent_date)}
            </span>
        );
    }
    let isOverdue = false;
    let isDue = false;

    if (letter.due_date < today) {
        isOverdue = true;
    }

    if (today < letter.due_date) {
        isDue = diffHours(letter.due_date, today, 'round') <= 24;
    }

    return (
        <span
            className={clsx('signalizing-field', {
                'future': !(isDue || isOverdue),
                'warning': isDue,
                'danger': isOverdue
            })}
        >
            {!(isDue || isOverdue) && 'Planned'}{isDue && 'Due'}{isOverdue && 'Overdue'}: {formatToUKDate(letter.due_date)}
        </span>
    );
};

const calendar = (options: ColumnEditorOptions) => {
    return <BaseCalendar
        value={options.value}
        minDate={options.rowData?.created_at}
        maxDate={new Date()}
        onChange={(e) => options.editorCallback(e.value)}
    />;
};

/**
 * Files datatable
 */
const ComplaintLettersDatatable: React.FC<ComplaintLettersDatatableProps> = ({
                                                                                 complaint
                                                                             }: ComplaintLettersDatatableProps) => {
    const queryClient = useQueryClient();
    const addToastMessage = useToastMessagesStore((state) => state.addToastMessage);
    const [previewFile, setPreviewFile] = useState<Letter | null>(null);
    const [previewVisible, setPreviewVisible] = useState<boolean>(false);
    const setLetterSentDateMutation = useLetterSentDate();

    const [confirmVisible, setConfirmVisible] = useState<boolean>(false);
    const [rowData, setRowData] = useState<DataTableRowEditCompleteEvent | null>(null);
    const {mutate} = useUpdateComplaintAllActions();
    const {
        data: letters,
        isLoading: lettersLoading
    } = useGetComplaintLetters({
        requestParams: {
            complaint_id: complaint?.id
        }
    });

    const onPreviewClick = (file: Letter) => {
        setPreviewFile(file);
        setPreviewVisible(true);
    };

    const closePreview = useCallback(() => {
        setPreviewFile(null);
        setPreviewVisible(false);
    }, [setPreviewVisible]);

    const isFinalLetter = rowData?.newData?.type === LetterType.FinalUpheld || rowData?.newData?.type === LetterType.FinalNotUpheld;

    const onRowEditComplete = async (e: DataTableRowEditCompleteEvent) => {
        setRowData(e);
        if (e.newData.type === LetterType.FinalUpheld || e.newData.type === LetterType.FinalNotUpheld) {
            setConfirmVisible(true);
        } else {

            await handleUpdateLetter(e);
        }
    };

    const handleUpdateLetter = async (e: DataTableRowEditCompleteEvent, clearAll: boolean = false) => {
        await setLetterSentDateMutation.mutateAsync({
            letter_id: e.newData.id,
            UpdateLetterSentRequest: {
                sent_date: formatToApiDate(e.newData.sent_date),
            }
        }, {
            onSuccess: () => {
                addToastMessage(ComplaintDocumentSentDateSavedMessage);
                if (clearAll && isFinalLetter) {
                    mutate(
                        {complaint_id: complaint.id},
                        {
                            onSuccess: () => {
                                addToastMessage(ComplaintAllDocumentSentDateSavedMessage);
                                void Promise.all([
                                    queryClient.invalidateQueries({
                                        queryKey: QueryKeys.activityLog.list({complaint_id: complaint?.id}).queryKey
                                    }),
                                    queryClient.invalidateQueries({
                                        queryKey: QueryKeys.letters._def
                                    }),
                                    queryClient.invalidateQueries({
                                        queryKey: QueryKeys.complaints._def
                                    })
                                ]);
                                setConfirmVisible(false);
                            },
                            onError: (error) => {
                                addToastMessage(CustomErrorMessage(error));
                                setConfirmVisible(false);
                            },
                        }
                    );
                }
                void Promise.all([
                    queryClient.invalidateQueries({
                        queryKey: QueryKeys.activityLog.list({complaint_id: complaint?.id}).queryKey
                    }),
                    queryClient.invalidateQueries({
                        queryKey: QueryKeys.letters._def
                    }),
                    queryClient.invalidateQueries({
                        queryKey: QueryKeys.complaints._def
                    })
                ]);
            },
            onError: error => {
                addToastMessage(CustomErrorMessage(error));
            }
        });
    };


    const onConfirm = async (clearAll: boolean) => {
        await handleUpdateLetter(rowData, clearAll);
    };

    const tableColumns: TableColumnDefinition[] = [
        {
            label: 'ID',
            field: 'id'
        },
        {
            label: 'File name',
            body: (data) => {
                return truncateString(data.document?.name ?? '');
            }
        },
        {
            label: 'Type',
            body: (data) => {
                return snakeToNormalCase(data.type);
            }
        },
        {
            label: 'State',
            field: 'sent_date',
            body: stateBodyRenderGenerator,
            editor: complaint && calendar
        },
        {
            rowEditor: (data) => !data.sent_date
        },
        {
            label: 'Actions',
            body: data => {
                return <div className='flex gap-2'>
                    <DownloadButton file={data.document} complaintId={data.complaint_id}/>
                    <Button label='Preview' onClick={() => onPreviewClick(data)}/>
                </div>;
            }
        },
    ];

    return (
        <StyledWrap>
            <div className="content-container">
                <div className="datatable-container">
                    <DataTable
                        lazy
                        loading={lettersLoading}
                        emptyMessage="No letters found."
                        value={letters}
                        editMode='row'
                        pt={dataTablePtOptions}
                        onRowEditComplete={onRowEditComplete}
                    >
                        {tableColumns.map((column, index) =>
                            <Column
                                key={`letters-column-${index}`}
                                field={column.field}
                                body={column.body}
                                header={column.label}
                                editor={column.editor}
                                rowEditor={column.rowEditor}
                            />
                        )}
                    </DataTable>
                </div>
            </div>
            {previewFile && <PreviewFileModal
                visible={previewVisible}
                closePreview={closePreview}
                file={previewFile.document}
                complaint_id={previewFile.complaint_id}
            />}
            <ConfirmDialog
                visible={confirmVisible}
                onHide={() => setConfirmVisible(false)}
                message="Are you sure you want to clean all actions?"
                header="Confirmation"
                icon="pi pi-exclamation-triangle"
                accept={() => onConfirm(true)}
                reject={() => {
                    onConfirm(false);
                    setConfirmVisible(false);
                }}
                style={{width: '50vw'}}
                breakpoints={{'1100px': '75vw', '960px': '100vw'}}
            />
        </StyledWrap>
    );
};

export default ComplaintLettersDatatable;
