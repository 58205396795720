import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useSearchParams } from 'react-router-dom';
import { InboxPresetOptions } from '@/Enums/InboxPresetOptions';
import { useInboxFilterPresets } from '@/Hooks/useInboxFilterPresets';
import ContentHeader from '@/components/Blocks/ContentHeader';
import BaseTextInput from "@/components/Core/Form/BaseTextInput";
import { GetComplaintsRequest } from "@/stub";
import { useDebounce } from "react-use";
import { useImpersonatedOrgId } from "@/Hooks/useImpersonatedOrgId";
import { TableFilters } from "@/components/Filters/FiltersList";
import useGetInboxFiltersQuery, { InboxFilterType } from "@/Hooks/useGetInboxFiltersQuery";
import { formatToApiDate } from "@/Util/formatToApiDate";
import { useUpdateSearchParams } from "@/Hooks/useUpdateSearchParams";
import { classNames } from "primereact/utils";
import DeletedFilterPresetsList from "@/components/Filters/Deleted/DeletedFilterPresetsList";
import DeletedInboxFiltersList from "@/components/Filters/Deleted/DeletedInboxFiltersList";
import DeletedComplaintsDatatable from "@/components/Datatables/DeletedComplaintsDatatable";


const StyledWrap = styled.main`
    .content-container {
        margin-top: 2rem;
        padding: 0 1rem;
    }

    .page-actions {
        margin-top: 2rem;
        padding: 1rem;
        background: #fff;
        border-radius: 8px;
    }
`;


export type InboxPageFilters = TableFilters & {
    search?: string | null
    selectedFilterPreset: InboxPresetOptions
};

export type InboxPageProps = {
    className?: string
};

const DeletedComplaintsPage: React.FC<InboxPageProps> = ({ className }: InboxPageProps) => {
    const {
        customerQuery,
        ownerQuery,
        presetsQuery,
        rootCauseIdQuery,
        statesQuery,
        productIdQuery,
        dataRangeQueryFrom,
        dataRangeQueryTo,
        searchQuery
    } = useGetInboxFiltersQuery();
    const [searchParams] = useSearchParams();
    const impersonatedOrgId = useImpersonatedOrgId();
    const inboxFilterPresets = useInboxFilterPresets();
    const updateFilterHandler = useUpdateSearchParams();


    useDebounce(
        () => {
            updateFilterHandler(InboxFilterType.Search, searchQuery);
        },
        600,
        [searchQuery]
    );


    const filterPreset: InboxPresetOptions = useMemo(() => {

        if (presetsQuery) {
            return presetsQuery as InboxPresetOptions;
        }
        return !impersonatedOrgId ? InboxPresetOptions.MyQueue : InboxPresetOptions.Today;
    }, [impersonatedOrgId, presetsQuery]);


    const requestParams = useMemo<GetComplaintsRequest>(() => {

        const selectedPreset = { ...inboxFilterPresets[filterPreset] };
        const fromDate = dataRangeQueryFrom ? new Date(dataRangeQueryFrom) : selectedPreset.from;
        const toDate = dataRangeQueryTo ? new Date(dataRangeQueryTo) : selectedPreset.to;

        if (searchParams.get('customer_id')) {
            selectedPreset.customer_id = Number(searchParams.get('customer_id')) || undefined;
        }

        if (searchParams.get('assigned_to_id')) {
            selectedPreset.assigned_to_id = Number(searchParams.get('assigned_to_id')) || undefined;
        }

        if (searchParams.get('root_cause_id')) {
            selectedPreset.root_cause_id = Number(searchParams.get('root_cause_id')) || undefined;
        }

        if (searchParams.get('states')) {
            selectedPreset.states = searchParams.get('states') || undefined;
        }

        if (searchParams.get('product_id')) {
            selectedPreset.product_id = Number(searchParams.get('product_id')) || undefined;
        }


        return {
            ...selectedPreset,// Spread selected preset filters
            only_trashed: true,
            from: formatToApiDate(fromDate),  // Format and set the 'from' date
            to: formatToApiDate(toDate),  // Format and set the 'to' date
            search: searchQuery || undefined,  // Set the search or undefined
        };
    }, [
        filterPreset,
        searchParams,
        impersonatedOrgId,
        searchQuery,
        inboxFilterPresets,
        presetsQuery,
        dataRangeQueryFrom,
        dataRangeQueryTo,
        ownerQuery,
        rootCauseIdQuery,
        statesQuery,
        customerQuery,
        productIdQuery
    ]);

    useEffect(() => {
        if (presetsQuery !== 'All') {
            updateFilterHandler(InboxFilterType.CustomerId, '');
            updateFilterHandler(InboxFilterType.OwnerId, '');
            updateFilterHandler(InboxFilterType.RootCauseId, '');
            updateFilterHandler(InboxFilterType.ProductId, '');
            updateFilterHandler(InboxFilterType.States, '');
            updateFilterHandler(InboxFilterType.DateRangeFrom, '');
            updateFilterHandler(InboxFilterType.DateRangeTo, '');
        }
    }, [presetsQuery]);


    return (
        <div>
            <StyledWrap className={className}>
                <ContentHeader
                    title={'Deleted Complaints'}
                    description={'Forseti Complaints Manager'}
                    rightSectionTemplate={
                        <BaseTextInput
                            value={searchQuery || ''}
                            placeholder="Search"
                            onChange={(e) => {
                                updateFilterHandler(InboxFilterType.Search, e.target.value);
                            }}
                        />
                    }
                />
                <div className="content-container">
                    <div className="page-actions">
                        <div className={classNames('flex justify-content-between', {
                            'mb-3': presetsQuery === 'All',
                            'mb-0': presetsQuery !== 'All'
                        })}>
                            <DeletedFilterPresetsList
                                value={filterPreset}
                                onChange={(presetName) => {
                                    updateFilterHandler(InboxFilterType.Presets, presetName);
                                }}
                            />
                        </div>
                        {presetsQuery === 'All' && <DeletedInboxFiltersList
                            className="flex gap-2 flex-wrap justify-content-start"
                        />}
                    </div>
                    <DeletedComplaintsDatatable
                        requestParams={requestParams}
                    />
                </div>
            </StyledWrap>
        </div>
    );
};

export default DeletedComplaintsPage;
