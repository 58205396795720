/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetOrganisations200Response,
  Organisation,
  ResetOrgRequest,
} from '../models/index';
import {
    GetOrganisations200ResponseFromJSON,
    GetOrganisations200ResponseToJSON,
    OrganisationFromJSON,
    OrganisationToJSON,
    ResetOrgRequestFromJSON,
    ResetOrgRequestToJSON,
} from '../models/index';

export interface GetOrganisationRequest {
    organisation_id: number;
}

export interface GetOrganisationsRequest {
    limit?: number;
    search?: string;
    page?: number;
}

export interface ResetToConfigRequest {
    organisation_id: number;
    ResetOrgRequest?: ResetOrgRequest;
}

/**
 * 
 */
export class OrganisationsApi extends runtime.BaseAPI {

    /**
     * Get organisation by ID
     */
    async getOrganisationRaw(requestParameters: GetOrganisationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Organisation>> {
        if (requestParameters.organisation_id === null || requestParameters.organisation_id === undefined) {
            throw new runtime.RequiredError('organisation_id','Required parameter requestParameters.organisation_id was null or undefined when calling getOrganisation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organisations/{organisation_id}`.replace(`{${"organisation_id"}}`, encodeURIComponent(String(requestParameters.organisation_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganisationFromJSON(jsonValue));
    }

    /**
     * Get organisation by ID
     */
    async getOrganisation(requestParameters: GetOrganisationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Organisation> {
        const response = await this.getOrganisationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get list of organisations
     */
    async getOrganisationsRaw(requestParameters: GetOrganisationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetOrganisations200Response>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organisations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetOrganisations200ResponseFromJSON(jsonValue));
    }

    /**
     * Get list of organisations
     */
    async getOrganisations(requestParameters: GetOrganisationsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetOrganisations200Response> {
        const response = await this.getOrganisationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reset organisation to initial data state
     */
    async resetToConfigRaw(requestParameters: ResetToConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organisation_id === null || requestParameters.organisation_id === undefined) {
            throw new runtime.RequiredError('organisation_id','Required parameter requestParameters.organisation_id was null or undefined when calling resetToConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organisations/{organisation_id}/restore`.replace(`{${"organisation_id"}}`, encodeURIComponent(String(requestParameters.organisation_id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResetOrgRequestToJSON(requestParameters.ResetOrgRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Reset organisation to initial data state
     */
    async resetToConfig(requestParameters: ResetToConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.resetToConfigRaw(requestParameters, initOverrides);
    }

}
