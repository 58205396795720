import React, { useCallback, useMemo, useState } from 'react';
import { DT_ROWS_NO } from '@/config/constants';
import styled from 'styled-components';
import {
    DataTable,
    DataTableDataSelectableEvent,
    type DataTablePassThroughOptions,
    type DataTableStateEvent
} from 'primereact/datatable';
import { ActionStateEnum, GetLettersRequest, GetLettersSortByEnum, Letter } from '@/stub';
import { produce } from 'immer';
import { Column, ColumnBodyOptions, ColumnEditorOptions } from "primereact/column";
import { Button } from "primereact/button";
import { formatToUKDate } from "@/Util/formatToUKDate";
import { useToastMessagesStore } from "@/Stores/ToastMessagesStore";
import { useQueryClient } from "@tanstack/react-query";
import { truncateString } from "@/Util/truncateString";
import PreviewFileModal from "@/components/Modals/PreviewFileModal";
import { useGetLetters } from "@/Service/Api/ApiHooks/Letter/useGetLetters";
import { snakeToNormalCase } from "@/helpers/general";
import { dayStart } from "@formkit/tempo";
import { clsx } from "clsx";
import { SortOrder } from "primereact/api";
import BaseCalendar from "@/components/Core/Form/BaseCalendar";
import BaseSwitchInput from "@/components/Core/Form/BaseSwitchInput";
import { useSendBatchLetters } from "@/Service/Api/ApiHooks/Letter/useSendBatchLetters";
import { QueryKeys } from "@/Service/Api/QueryKeys/QueryKeys";
import { CustomErrorMessage } from "@/Messages/Toast/General/CustomErrorMessage";
import { LettersBatchSentMessage } from "@/Messages/Toast/Letters/LettersBatchSentMessage";
import { downloadBlob } from "@/Util/downloadBlob";
import DownloadButton from "@/components/partials/DownloadButton";
import moment from "moment";
import { formatToApiDate } from "@/Util/formatToApiDate";
import { authUserCan } from "@/Util/permissionChecks";
import { useAuthUser } from "@/Hooks/useAuthUser";

type TableFilterOptions = {
    first: number
    rows: number
    sortField: GetLettersSortByEnum
    sortOrder: SortOrder
    showAll: boolean
};

type TableColumnDefinition = {
    label?: string
    field?: string
    body?: React.ReactNode | ((data: Letter, options: ColumnBodyOptions) => React.ReactNode)
    editor?: (options: ColumnEditorOptions) => React.ReactNode
    rowEditor?: (data: Letter) => boolean
};

const dataTablePtOptions: DataTablePassThroughOptions = {
    root: {
        className: 'datatable-base'
    }
};

const StyledWrap = styled.main`
    .datatable-base {
        font-size: 0.8rem;
        font-weight: 500;
    }

    .signalizing-field {
        padding: 0.15rem 0.5rem;
        border-radius: 6px;
        text-wrap: nowrap;

        &.future {
            background-color: var(--teal-200)
        }

        &.success {
            background-color: var(--green-200);
        }

        &.warning {
            background-color: var(--yellow-100);
        }

        &.danger {
            background-color: var(--red-200);
        }
    }
`;

export type LettersDatatableProps = {
    requestParams?: Partial<GetLettersRequest>
};

const today = new Date();

const stateBodyRenderGenerator = (letter: Letter) => {
    if (letter.sent_date) {
        return (
            <span className="signalizing-field success">
                Sent: {formatToUKDate(letter.sent_date)}
            </span>
        );
    }

    const isOverdue = dayStart(letter.due_date) < today;
    if (isOverdue) {
        return (
            <span
                className={clsx('signalizing-field', { warning: isOverdue })}
            >
                {isOverdue ? 'Overdue' : 'Due'}: {formatToUKDate(letter.due_date)}
            </span>
        );
    }

    return (
        <span
            className="signalizing-field future"
        >
            Planned: {formatToUKDate(letter.due_date)}
        </span>
    );
};

/**
 * Files datatable
 */
const LettersDatatable: React.FC<LettersDatatableProps> = ({
    requestParams
}: LettersDatatableProps) => {
    const queryClient = useQueryClient();
    const authUser = useAuthUser();
    const addToastMessage = useToastMessagesStore((state) => state.addToastMessage);
    const [previewFile, setPreviewFile] = useState<Letter | null>(null);
    const [previewVisible, setPreviewVisible] = useState<boolean>(false);
    const [selectedLetters, setSelectedLetters] = useState<Letter[]>([]);
    const [sentDate, setSentDate] = useState<Date | null>(null);
    const sendBatchLettersMutation = useSendBatchLetters();
    const [tableFilters, setTableFilters] = useState<TableFilterOptions>({
        rows: DT_ROWS_NO,
        first: 0,
        sortField: GetLettersSortByEnum.DueDate,
        sortOrder: SortOrder.DESC,
        showAll: false
    });

    const onPagination = (e: DataTableStateEvent) => {
        setTableFilters(produce(draft => {
            if (draft.rows !== e.rows) {
                draft.first = 0;
            } else {
                draft.first = e.first;
            }
            draft.rows = e.rows;
        }));
    };

    const lettersRequestParams = useMemo<GetLettersRequest>(() => {
        return {
            page: (tableFilters.first / tableFilters.rows) + 1,
            limit: tableFilters.rows,
            states: tableFilters.showAll ? undefined : [
                ActionStateEnum.DueTo,
                ActionStateEnum.PastDue
            ],
            ...requestParams
        };
    }, [requestParams, tableFilters]);

    const {
        data: letters,
        isLoading: lettersLoading
    } = useGetLetters({
        requestParams: lettersRequestParams
    });

    const minAvailableDate = useMemo(() => {
        let minDate = moment(selectedLetters?.[0] ? selectedLetters[0].created_at : today);
        selectedLetters?.forEach(letter => {
            if (!letter.sent_date) {
                const createdAt = moment(letter.created_at);
                if (createdAt.isBefore(minDate))
                    minDate = createdAt;
            }
        });
        return new Date(minDate);
    }, [selectedLetters]);

    const isSelectable = (data: Letter) => !data.sent_date;
    const isRowSelectable = (e: DataTableDataSelectableEvent) => isSelectable(e.data);
    const rowClassName = (data: Letter) => (isSelectable(data) ? '' : 'p-disabled');

    const onPreviewClick = (file: Letter) => {
        setPreviewFile(file);
        setPreviewVisible(true);
    };

    const closePreview = useCallback(() => {
        setPreviewFile(null);
        setPreviewVisible(false);
    }, [setPreviewVisible]);

    const onBatchMutationSuccess = (blob: Blob) => {
        downloadBlob(blob);
        setSentDate(null);
        setSelectedLetters([]);
        addToastMessage(LettersBatchSentMessage);
        void Promise.all([
            queryClient.invalidateQueries({
                queryKey: QueryKeys.letters._def
            })
        ]);
    };

    const onSendClick = async () => {
        await sendBatchLettersMutation.mutateAsync({
            UpdateLetterSentBatchRequest: {
                sent_date: formatToApiDate(sentDate),
                ids: selectedLetters.map(letter => letter.id),
            }
        }, {
            onSuccess: onBatchMutationSuccess,
            onError: error => {
                addToastMessage(CustomErrorMessage(error));
            }
        });
    };

    const tableHeader = () => {
        return (
            <div className="flex flex-wrap align-items-end gap-2 justify-content-between">
                <BaseSwitchInput
                    tooltip={tableFilters.showAll ? 'Show only unsent letters' : 'Show all'}
                    checked={tableFilters.showAll}
                    onChange={(e) => {
                        setTableFilters(produce(draft => {
                            draft.showAll = e.value;
                        }));
                    }}
                />
                <div className='flex align-items-end justify-content-center gap-1'>
                    <BaseCalendar
                        label='Sent Date'
                        value={sentDate}
                        disabled={!selectedLetters.length}
                        minDate={minAvailableDate}
                        maxDate={today}
                        onChange={(e) => {
                            setSentDate(e.value as Date);
                        }}
                    />
                    <Button
                        label='Send & Download'
                        onClick={onSendClick}
                        disabled={!selectedLetters.length}
                        loading={sendBatchLettersMutation.isPending}
                    />
                </div>
            </div>
        );
    };

    const isSentDateEditable = (letter: Letter) => {
        if (letter.sent_date) {
            return false;
        }
        if (authUserCan('set:sent_letter_date')) {
            return true;
        }

        return (authUserCan('set:sent_src_letter_date') || authUserCan('set:sent_frl_letter_date')) && letter.complaint?.owner?.id === authUser?.id;
    };

    const tableColumns: TableColumnDefinition[] = [
        {
            label: 'ID',
            field: 'id'
        },
        {
            label: 'File name',
            body: (data) => {
                return truncateString(data.document?.name ?? '');
            }
        },
        {
            label: 'Type',
            body: (data) => {
                return snakeToNormalCase(data.type);
            }
        },
        {
            label: 'Complaint Reference',
            field: 'complaint_id'

        },
        {
            label: 'State',
            field: 'sent_date',
            body: stateBodyRenderGenerator
        },
        {
            rowEditor: isSentDateEditable
        },
        {
            label: 'Actions',
            body: data => {
                return <div className='flex gap-2'>
                    <DownloadButton file={data.document} complaintId={data.complaint_id}/>
                    <Button label='Preview' onClick={() => onPreviewClick(data)}/>
                </div>;
            }
        },
    ];

    return (
        <StyledWrap>
            <div className="content-container">
                <div className="datatable-container">
                    <DataTable
                        lazy
                        loading={lettersLoading}
                        emptyMessage="No letters found."
                        value={letters?.data}
                        totalRecords={letters?.meta.total}
                        paginator
                        selectionMode='multiple'
                        selection={selectedLetters}
                        onSelectionChange={(e) => setSelectedLetters(e.value)}
                        isDataSelectable={isRowSelectable}
                        rowClassName={rowClassName}
                        dataKey='id'
                        header={tableHeader}
                        rows={tableFilters.rows}
                        rowsPerPageOptions={[10, 20, 50]}
                        first={tableFilters.first}
                        pt={dataTablePtOptions}
                        onPage={onPagination}
                    >
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        {tableColumns.map((column, index) =>
                            <Column
                                key={`letters-column-${index}`}
                                field={column.field}
                                body={column.body}
                                header={column.label}
                                editor={column.editor}
                                rowEditor={column.rowEditor}
                            />
                        )}
                    </DataTable>
                </div>
            </div>
            {previewFile && <PreviewFileModal
                visible={previewVisible}
                closePreview={closePreview}
                file={previewFile.document}
                complaint_id={previewFile.complaint_id}
            />}
        </StyledWrap>
    );
};

export default LettersDatatable;
