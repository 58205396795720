/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResetOrgRequest
 */
export interface ResetOrgRequest {
    /**
     * 
     * @type {number}
     * @memberof ResetOrgRequest
     */
    organisation_id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ResetOrgRequest
     */
    is_fresh?: boolean;
}

/**
 * Check if a given object implements the ResetOrgRequest interface.
 */
export function instanceOfResetOrgRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ResetOrgRequestFromJSON(json: any): ResetOrgRequest {
    return ResetOrgRequestFromJSONTyped(json, false);
}

export function ResetOrgRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResetOrgRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'organisation_id': !exists(json, 'organisation_id') ? undefined : json['organisation_id'],
        'is_fresh': !exists(json, 'is_fresh') ? undefined : json['is_fresh'],
    };
}

export function ResetOrgRequestToJSON(value?: ResetOrgRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'organisation_id': value.organisation_id,
        'is_fresh': value.is_fresh,
    };
}

